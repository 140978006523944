<template>
  <daily-view :date.sync="now" />
</template>

<script>
import DailyView from '../routine/DailyView.vue';

export default {
  components: { DailyView },

  data() {
    return {
      now: new Date(),
    };
  },
};
</script>
