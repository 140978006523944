<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>{{ text }}</v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text @click="rejection">{{ cancel }}</v-btn>

        <v-btn :color="buttonColor" @click="acception">{{ ok }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Are you sure?',
    },

    text: {
      type: String,
      default: '',
    },

    cancel: {
      type: String,
      default: 'Cancel',
    },

    ok: {
      type: String,
      default: 'OK',
    },

    buttonColor: {
      type: String,
      default: 'primary',
    },
  },

  data() {
    return {
      dialog: false,
      accept: null,
      decline: null,
    };
  },

  methods: {
    open() {
      this.dialog = true;

      return new Promise((resolve, resject) => {
        this.accept = resolve;
        this.decline = resject;
      });
    },

    acception() {
      this.dialog = false;
      this.accept();
    },

    rejection() {
      this.dialog = false;
      this.decline();
    },
  },
};
</script>
