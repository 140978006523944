<template>
  <v-card
    class="mb-4 mx-7 task"
    :class="{ 'pl-5': task.planned || draggable }"
    outlined
    style="position: relative;"
    :style="isActive ? 'border-color: rgba(255, 0, 0, .75);' : ''"
    :color="task.done ? 'done' : ''"
  >
    <div
      v-if="!task.planned && draggable"
      class="drag-handle"
      style="
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 10px;
        background: rgba(255, 255, 255, 0.12);
        cursor: move;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      "
    />

    <div
      v-if="showDate"
      class="pt-2"
      :style="{'margin-left': !task.planned || draggable ? '16px' : 0}"
      data-testid="task-date"
    >
      {{ task.date }}
    </div>

    <div
      v-if="task.planned"
      class="time-separator"
      :class="{ active: isActive }"
      data-testid="task-time-separator"
    />

    <div v-if="task.planned" class="mt-3" data-testid="task-start">{{ task.start }}</div>

    <v-card-title style="align-items: baseline;">
      <v-col>
        <v-row style="flex-wrap: nowrap;">
          <v-checkbox
            v-if="checkable"
            v-model="task.done"
            hide-details
            class="mt-0"
            @change="$emit('check-change')"
            data-testid="task-done"
          />

          <div style="word-break: break-word;" data-testid="task-title">{{ task.title }}</div>
        </v-row>
      </v-col>

      <v-col style="flex-shrink: 1; flex-grow: 0;">
        <v-row style="flex-wrap: nowrap;">
          <v-btn
            v-if="task.has_checklist"
            @click="checklistIsOpened = !checklistIsOpened"
            text
            class="mr-2"
            data-testid="task-todos"
          >
            <v-icon>mdi-format-list-checks</v-icon>
            Teendők
            ({{ task.checklist.filter(i => i.done).length }}/{{ task.checklist.length }})
          </v-btn>

          <v-tooltip v-if="task.recurring" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="mr-2" data-testid="task-recurring-icon">
                mdi-restore
              </v-icon>
            </template>

            <span>
              Ez a feladat ismétlődik
              {{ task.recurrence_frequency }}
              {{ recurringIntervals[task.recurrence_interval] }}

              <span v-if="task.recurrence_interval == 'week'">
                {{ getWeekdays(task.recurring_days) }} napokon
              </span>
            </span>
          </v-tooltip>

          <slot>
            <v-menu min-width="auto" offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" data-testid="task-actions-btn">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in subMenuItems.filter(menu => menu.show)"
                  :key="index"
                  @click="item.action"
                  :data-testid="item.testid"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </slot>
        </v-row>
      </v-col>
    </v-card-title>

    <v-card-text class="pl-8">
      <p>{{ task.description }}</p>

      <draggable
        v-if="checklistIsOpened"
        v-model="task.checklist"
        style="margin: auto; width: 50%; min-width: 250px; max-width: 500px;"
        handle=".checklist-drag-handle"
        @end="sortChecklist"
      >
        <v-hover v-for="item in task.checklist" :key="item.id" v-slot="{ hover }">
          <v-row class="align-center" data-testid="task-checklist-item">
            <v-icon
              class="checklist-drag-handle"
              style="cursor: move;"
              :style="{ visibility: hover ? 'visible' : 'hidden' }"
            >
              mdi-drag
            </v-icon>

            <v-checkbox
              :disabled="!checkable"
              :label="item.title"
              v-model="item.done"
              hide-details
              class="my-2"
              @change="$emit('toggle-checklist-item-done', item)"
            />

            <v-spacer />

            <v-btn icon v-show="hover" class="mt-1" @click="$emit('delete-checklist-item', item)">
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
          </v-row>
        </v-hover>

        <v-text-field
          hide-details
          placeholder="Új teendő hozzáadása"
          v-model="addChecklistItemTitle"
          @keyup.enter="addChecklistItem"
          data-testid="task-create-todo-item"
        />
      </draggable>

      <v-subheader v-if="task.doneOn">Elkészült: {{ task.doneOn }}</v-subheader>
    </v-card-text>

    <div v-if="task.planned" class="mb-3" data-testid="task-end">{{ task.end }}</div>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: { draggable },

  props: {
    task: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    checkable: {
      type: Boolean,
      default: true,
    },

    draggable: {
      type: Boolean,
      default: false,
    },

    showDate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checklistIsOpened: false,
      addChecklistItemTitle: '',
      subMenuItems: [
        {
          icon: 'mdi-pencil',
          title: 'Szerkesztés',
          show: true,
          testid: 'task-edit-btn',
          action: () => {
            this.$emit('edit');
          },
        },
        {
          icon: 'mdi-chevron-right',
          title: 'Holnapra halasztás',
          show: !this.task.recurring && !!this.task.date,
          testid: 'task-postpone-btn',
          action: () => {
            this.$emit('postpone');
          },
        },
        {
          icon: 'mdi-inbox-arrow-up',
          title: 'Move to workspace',
          show: this.$store.state.workspace.workspaces.length > 1,
          testid: 'task-move-to-workspace-btn',
          action: () => {
            this.$emit('moveToWorkspace');
          },
        },
        {
          icon: 'mdi-delete-forever',
          title: 'Törlés',
          show: true,
          testid: 'task-delete-btn',
          action: () => {
            this.$emit('delete');
          },
        },
      ],
      recurringIntervals: {
        day: 'naponta',
        week: 'hetente',
        month: 'havonta',
        year: 'évente',
      },
      recurringWeekdays: {
        monday: 'Hétfő',
        tuesday: 'Kedd',
        wednesday: 'Szerda',
        thursday: 'Csütörtök',
        friday: 'Péntek',
        saturday: 'Szombat',
        sunday: 'Vasárnap',
      },
    };
  },

  methods: {
    async addChecklistItem() {
      await this.$store.dispatch('saveChecklistItem', {
        task: this.task,
        checklistItem: {
          title: this.addChecklistItemTitle,
        },
      });
      this.addChecklistItemTitle = '';
      this.$emit('change');
    },

    getWeekdays(recurringDays) {
      return recurringDays.map((day) => this.recurringWeekdays[day]).join(', ');
    },

    sortChecklist() {
      this.$store.dispatch('sortChecklist', { task: this.task });
    },
  },
};
</script>

<style lang="scss">
@keyframes current-timing-animation {
  0% {
    background-position: left top;
  }

  100% {
    background-position: left 12px;
  }
}

.v-card.task {
  .time-separator {
    position: absolute;
        top: 45px;
        left: 22px;
        bottom: 45px;
        width: 4px;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.12),
          rgba(255, 255, 255, 0.12) 50%,
          transparent 50%,
          transparent
        );
        background-size: 100% 12px;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;

        &.active {
          background: linear-gradient(
            to bottom,
            rgba(255, 0, 0, .75),
            rgba(255, 0, 0, .75) 50%,
            transparent 50%,
            transparent
          );
          background-size: 100% 12px;
          animation: current-timing-animation 1s infinite linear;
        }
  }
}
</style>
