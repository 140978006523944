<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-form ref="form" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          {{ financeData.id ? 'Edit' : 'Create' }}
          {{ financeData.type == 'expense' ? 'expense' : 'income' }}

          <v-spacer />

          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="financeData.title" label="Title" data-testid="finance-title" />

          <v-text-field
            v-model="financeData.amount"
            label="Amount"
            type="number"
            data-testid="finance-amount"
          />

          <v-combobox
            v-model="financeData.category"
            label="Category"
            :items="categories"
            :search-input.sync="categorySearch"
            @change="createCategory"
            data-testid="finance-category"
          >
            <template v-slot:append-item>
              <v-list-item
                v-if="
                  categorySearch && !categories.some(category => category.text == categorySearch)
                "
              >
                <v-list-item-content>
                  <v-list-item-title>
                    "Create <strong>{{ categorySearch }}</strong>" (Enter)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:no-data>
              <!-- így nem jelenik meg kétszer a hozzáadás legördülő -->
              <v-list-item style="min-height: 0px;" />
            </template>
          </v-combobox>

          <v-subheader>Date</v-subheader>

          <v-date-picker
            v-model="financeData.date"
            :first-day-of-week="1"
            locale="en-us"
            full-width
            color="primary"
          />
        </v-card-text>

        <v-card-actions>
          <v-checkbox
            label="Review"
            v-model="financeData.shouldReview"
            data-testid="finance-review"
          />

          <v-spacer />

          <v-btn text @click="close">Cancel</v-btn>

          <v-btn color="primary" type="submit" data-testid="finance-save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      categorySearch: '',
      financeData: {
        title: '',
        amount: 0,
        category: { text: 'No category', value: -1 },
        date: '',
        shouldReview: false,
      },
    };
  },

  computed: {
    ...mapState('finance', ['categories']),
  },

  methods: {
    open(data) {
      if (data && data.finance_category_id) {
        // eslint-disable-next-line no-param-reassign
        data.category = this.categories.find((el) => el.value === data.finance_category_id);
      }

      this.financeData = {
        title: data.title || '',
        amount: data.amount || 0,
        category: data.category || { text: 'No category', value: -1 },
        date: data.date || format(new Date(), 'yyyy-MM-dd'),
        shouldReview: data.shouldReview || false,
        type: data.type || 'expense',
      };
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    close() {
      this.reject();
      this.dialog = false;
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.resolve({
          title: this.financeData.title,
          amount: this.financeData.amount,
          category: this.financeData.category.value,
          date: this.financeData.date,
          shouldReview: this.financeData.shouldReview,
          type: this.financeData.type,
        });
        this.dialog = false;
      }
    },

    createCategory() {
      // TODO
      if (this.categorySearch && typeof this.financeData.category === 'string') {
        const element = {
          text: this.categorySearch,
          value: this.categories.length,
        };

        this.categories.push(element);
        this.financeData.category = element;
      }
    },
  },
};
</script>
