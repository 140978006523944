<template>
  <category-table :should-show-actions="false" />
</template>

<script>
import { mapActions } from 'vuex';
import CategoryTable from '../finance/CategoryTable.vue';
import state from '../../store/modules/finance';

export default {
  components: { CategoryTable },

  methods: {
    ...mapActions('finance', ['listCategories']),
  },

  mounted() {
    this.listCategories();
  },

  created() {
    if (!this.$store.hasModule('finance')) {
      this.$store.registerModule('finance', state);
    }
  },
};
</script>
