<template>
  <layout title="Dashboard">
    <template v-slot:menu>
      <v-spacer />
    </template>

    <template v-slot:content>
      <div id="content">
        <grid-layout
          ref="gridlayout"
          :layout.sync="layout"
          :col-num="12"
          :row-height="30"
          is-draggable
          is-resizable
          responsive
          :is-mirrored="false"
          vertical-compact
          :margin="[10, 10]"
          use-css-transforms
          style="min-height: 150px;"
      >
          <grid-item
            v-for="item in layout"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
            class="pa-1"
            style="background-color: #333; border-radius: 3px; touch-action: none;"
            drag-allow-from=".draggable-handle"
            drag-ignore-from=".no-drag"
            @moved="save"
            @resized="save"
          >
            <v-toolbar dense>
              <v-icon class="draggable-handle">mdi-drag</v-icon>

              {{ item.title }}
            </v-toolbar>

            <component :is="item.component" style="overflow: auto; height: calc(100% - 48px);" />
          </grid-item>
        </grid-layout>
      </div>

      <v-navigation-drawer v-model="drawer" fixed right>
        <template v-slot:prepend>
          <v-row class="ma-0 py-3 pr-2 align-center">
            <v-subheader>
              Drag'n'drop your widget
            </v-subheader>

            <v-spacer />

            <v-btn icon small @click="drawer = !drawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </template>

        <v-list>
          <v-list-item
            v-for="(widget, index) in widgets"
            :key="index"
            @drag="drag(widget)"
            @dragstart="dragstart"
            @dragend="dragend(widget)"
            draggable="true"
          >
            <v-list-item-content>
              <v-list-item-title>{{ widget.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-btn fab fixed bottom right color="primary" @click.stop="drawer = !drawer">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
  </layout>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
// import { v4 as uuidv4 } from 'uuid';
import Layout from '../layouts/Main.vue';
import DailyTasks from '../components/dashboard/DailyTasks.vue';
import MonthlyFinances from '../components/dashboard/MonthlyFinances.vue';
import MonthlyFinanceCategories from '../components/dashboard/MonthlyFinanceCategories.vue';

const mouseXY = { x: null, y: null };
const DragPos = {
  x: null, y: null, w: 1, h: 1, i: null,
};

export default {
  components: {
    Layout,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    DailyTasks,
    MonthlyFinances,
    MonthlyFinanceCategories,
  },

  data() {
    return {
      drawer: false,
      dragCounter: 0,
      layout: [],
      widgets: [
        {
          title: 'Daily tasks', w: 6, h: 10, component: 'daily-tasks',
        },
        {
          title: 'Monthly finances', w: 6, h: 10, component: 'monthly-finances',
        },
        {
          title: 'Monthly finance categories', w: 6, h: 10, component: 'monthly-finance-categories',
        },
      ],
    };
  },

  /* watch: {
    layout() {
      console.log(this.layout);
    },
  }, */

  methods: {
    drag(data) {
      this.dragCounter += 1;

      // TODO: kell lennie jobb megoldásnak arra, hogy ne lassítsa be a drag a mozgatást
      // (túl sokszor rendereli újra a dolgokat, ezért belassul a cucc)
      if (this.dragCounter % 20 !== 0) {
        return;
      }

      const parentRect = document.getElementById('content').getBoundingClientRect();
      const mouseInGrid = mouseXY.x > parentRect.left
        && mouseXY.x < parentRect.right
        && mouseXY.y > parentRect.top
        && mouseXY.y < parentRect.bottom;

      if (mouseInGrid && (this.layout.findIndex((item) => item.i === 'drop')) === -1) {
        this.layout.push({
          x: (this.layout.length * 2) % (this.colNum || 12),
          y: this.layout.length + (this.colNum || 12), // puts it at the bottom
          w: data.w,
          h: data.h,
          i: 'drop',
          title: '',
        });
      }

      const index = this.layout.findIndex((item) => item.i === 'drop');

      if (index !== -1) {
        try {
          this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = 'none';
        } catch {
          //
        }

        const el = this.$refs.gridlayout.$children[index];
        el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left };
        const newPos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

        if (mouseInGrid) {
          this.$refs.gridlayout.dragEvent('dragstart', 'drop', newPos.x, newPos.y, data.h, data.w);
          DragPos.i = String(index);
          DragPos.x = this.layout[index].x;
          DragPos.y = this.layout[index].y;
        } else {
          this.$refs.gridlayout.dragEvent('dragend', 'drop', newPos.x, newPos.y, data.h, data.w);
          this.layout = this.layout.filter((obj) => obj.i !== 'drop');
        }
      }
    },

    dragstart() {
      this.dragCounter = 0;
    },

    dragend(data) {
      const parentRect = document.getElementById('content').getBoundingClientRect();
      const mouseInGrid = ((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right))
        && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom));

      if (mouseInGrid) {
        this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, data.h, data.w);
        this.layout = this.layout.filter((obj) => obj.i !== 'drop');

        this.layout.push({
          x: DragPos.x,
          y: DragPos.y,
          w: data.w,
          h: data.h,
          i: DragPos.i,
          title: data.title,
          component: data.component,
        });
        this.$refs.gridlayout.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, data.h, data.w);

        try {
          this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = 'block';
        } catch {
          //
        }

        this.save();
      }
    },

    save() {
      this.$store.dispatch('saveDashboard', { layout: this.layout });
    },
  },

  async mounted() {
    document.addEventListener('dragover', (e) => {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);

    this.layout = await this.$store.dispatch('loadDashboard');
  },
};
</script>
