<template>
  <div id="routine-daily-view">
    <v-row class="mx-0">
      <v-col class="shrink d-flex align-center pr-0">
        <v-btn icon @click="previousDay" large data-testid="previous-day">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>

      <v-col>
        <v-tabs v-model="currentDayOfWeekForTabs" height="64px" :show-arrows="false" grow>
          <v-tab
            v-for="day in currentWeek"
            :key="day.index"
            class="d-flex align-center flex-column pa-2"
            :class="{current: isCurrentDay(day.day)}"
          >
            <h4 style="font-weight: 900;">{{ day.dayOfWeek }}</h4>

            <v-subheader>{{ day.dayOfMonth }}</v-subheader>

            <div v-if="isCurrentDay(day.day)" class="current-day-mark" />
          </v-tab>
        </v-tabs>
      </v-col>

      <v-col class="shrink d-flex align-center pl-0">
        <v-btn icon @click="nextDay" large data-testid="next-day">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-expansion-panels class="px-2 py-8" inset multiple v-model="openTaskTypePanels">
      <v-row>
        <v-col cols="12" md="6">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon>mdi-alert-outline</v-icon>
                Múltbéli kimaradt feladatok
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <p v-if="oldTasks.length == 0">
                Juhúú, nem vagy elmaradva egy feladatoddal sem!
              </p>

              <task-card
                  v-for="task in oldTasks"
                  :key="task.id"
                  :task="task"
                  show-date
                  @edit="openEditTaskDialog(task)"
                  @check-change="handleTaskDone(task, true)"
                  @change="list"
                  @delete="openDeleteTaskDialog(task)"
                  @delete-checklist-item="openDeleteChecklistItemDialog(task, $event)"
                  @toggle-checklist-item-done="
                    $store.dispatch('toggleChecklistItemDone', {
                      task,
                      checklistItem: $event,
                      date: currentDayForDatePicker
                    })
                  "
                  @postpone="handlePostponeTask(task)"
                  @moveToWorkspace="moveToWorkspace(task)"
                  data-testid="past-task"
                />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon>mdi-check</v-icon>
                Ma
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <p v-if="tasks.length == 0">
                Erre a napra nincs elvégzendő feladat
              </p>

              <draggable v-model="tasks" handle=".drag-handle" @end="sortTodayTasks">
                <task-card
                  v-for="task in tasks"
                  :key="task.id"
                  :task="task"
                  draggable
                  @edit="openEditTaskDialog(task)"
                  @check-change="handleTaskDone(task)"
                  @change="list"
                  @delete="openDeleteTaskDialog(task)"
                  @delete-checklist-item="openDeleteChecklistItemDialog(task, $event)"
                  @toggle-checklist-item-done="
                    $store.dispatch('toggleChecklistItemDone', {
                      task,
                      checklistItem: $event,
                      date: currentDayForDatePicker
                    })
                  "
                  @postpone="handlePostponeTask(task)"
                  @moveToWorkspace="moveToWorkspace(task)"
                  data-testid="today-task"
                />
              </draggable>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon>mdi-information-outline</v-icon>
                Jövőbeli feladatok
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <p v-if="futureTasks.length == 0">
                Nincs fix programod a jövőre nézve!
              </p>

              <task-card
                v-for="task in futureTasks"
                :key="task.id"
                :task="task"
                show-date
                @edit="openEditTaskDialog(task)"
                @check-change="handleTaskDone(task, true)"
                @change="list"
                @delete="openDeleteTaskDialog(task)"
                @delete-checklist-item="openDeleteChecklistItemDialog(task, $event)"
                @toggle-checklist-item-done="
                  $store.dispatch('toggleChecklistItemDone', {
                    task,
                    checklistItem: $event,
                    date: currentDayForDatePicker
                  })
                "
                @postpone="handlePostponeTask(task)"
                @moveToWorkspace="moveToWorkspace(task)"
                data-testid="future-task"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-col>

        <v-col cols="12" md="6">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon>mdi-clock-time-nine-outline</v-icon>
                Tervezett feladatok
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <p v-if="plannedTasks.length == 0">
                Erre a napra nincs elvégzendő tervezett feladat
              </p>

              <task-card
                v-for="task in plannedTasks"
                :key="task.id"
                :task="task"
                @edit="openEditTaskDialog(task)"
                @check-change="handleTaskDone(task)"
                @change="list"
                @delete="openDeleteTaskDialog(task)"
                @delete-checklist-item="openDeleteChecklistItemDialog(task, $event)"
                @toggle-checklist-item-done="
                  $store.dispatch('toggleChecklistItemDone', {
                    task,
                    checklistItem: $event,
                    date: currentDayForDatePicker
                  })
                "
                @postpone="handlePostponeTask(task)"
                @moveToWorkspace="moveToWorkspace(task)"
                :isActive="task.running"
                data-testid="planned-task"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-col>
      </v-row>
    </v-expansion-panels>

    <choose-workspace-dialog ref="choooseWorkspaceDialog" />
    <delete-task-dialog ref="deleteTaskDialog" />
    <delete-checklist-item-dialog ref="deleteChecklistItemDialog" />
  </div>
</template>

<script>
import Vue from 'vue';
import draggable from 'vuedraggable';
import {
  format, getDay, startOfWeek, endOfWeek, eachDayOfInterval, isToday, addDays, formatISO, isAfter,
  isBefore, isSameDay,
} from 'date-fns';
import { hu } from 'date-fns/locale';
import { mapActions, mapState } from 'vuex';
import TaskCard from './TaskCard.vue';
import DeleteTaskDialog from '../dialog/DeleteTaskDialog.vue';
import DeleteChecklistItemDialog from '../dialog/DeleteChecklistItemDialog.vue';
import ChooseWorkspaceDialog from '../dialog/ChooseWorkspaceDialog.vue';

export default {
  components: {
    draggable,
    TaskCard,
    DeleteTaskDialog,
    DeleteChecklistItemDialog,
    ChooseWorkspaceDialog,
  },

  props: {
    date: {
      type: Date,
      required: true,
    },
  },

  data() {
    return {
      runningTaskIntervalHandler: null,
      oldTasks: [],
      futureTasks: [],
      tasks: [],
      plannedTasks: [],
      openTaskTypePanels: [1, 3],
    };
  },

  computed: {
    ...mapState('workspace', ['currentWorkspace']),

    currentWeek() {
      return eachDayOfInterval({
        start: startOfWeek(this.date, { weekStartsOn: 1 }),
        end: endOfWeek(this.date, { weekStartsOn: 1 }),
      }).map((day, index) => ({
        index,
        day,
        dayOfWeek: format(day, 'EEEEE', { locale: hu }),
        dayOfMonth: format(day, 'd', { locale: hu }),
      }));
    },

    currentDay() {
      return format(this.date, 'eeee', { locale: hu });
    },

    currentDayOfWeekForTabs: {
      get() {
        const dayOfWeek = getDay(this.date);

        if (dayOfWeek === 0) {
          return 6; // 0 represents Sunday
        }

        return dayOfWeek - 1;
      },

      set(value) {
        this.$emit('update:date', this.currentWeek[value].day);
      },
    },

    currentDayForDatePicker: {
      get() {
        return formatISO(this.date, { representation: 'date', locale: hu });
      },

      set(value) {
        this.$emit('update:date', new Date(value));
      },
    },
  },

  watch: {
    date() {
      this.list();
    },

    currentWorkspace() {
      this.list();
    },
  },

  methods: {
    ...mapActions(['sortTasks', 'moveTaskToWorkspace']),

    switchCalendarView() {},

    async list() {
      const result = await this.$store.dispatch('listTasks', { date: this.currentDayForDatePicker });
      this.oldTasks = result.old;
      this.futureTasks = result.future;
      this.tasks = result.regular;
      this.plannedTasks = result.planned;

      this.handleCurrentTaskVisualization();
    },

    isCurrentDay(day) {
      return isToday(day);
    },

    previousDay() {
      this.$emit('update:date', addDays(this.date, -1));
    },

    nextDay() {
      this.$emit('update:date', addDays(this.date, 1));
    },

    openEditTaskDialog(task) {
      const taskForEdit = task ? JSON.parse(JSON.stringify(task)) : undefined;

      this.$emit('edit', taskForEdit);
    },

    openDeleteTaskDialog(task) {
      this.$refs.deleteTaskDialog.open(task).then(async (type) => {
        await this.$store.dispatch('deleteTask', { task, type, date: this.currentDayForDatePicker });
        this.list();
      }).catch(() => {});
    },

    openDeleteChecklistItemDialog(task, checklistItem) {
      this.$refs.deleteChecklistItemDialog.open(checklistItem).then(() => {
        const index = task.checklist.findIndex((item) => item.id === checklistItem.id);

        this.$store.dispatch('deleteChecklistItem', { task, checklistItem });

        task.checklist.splice(index, 1);
      }).catch(() => {});
    },

    async handlePostponeTask(task) {
      await this.$store.dispatch('postponeTask', task);
      this.list();
    },

    handleTaskDone(task, reload = false) {
      this.$store.dispatch('toggleTaskDone', { task, date: this.currentDayForDatePicker });

      if (task.planned) {
        this.handleCurrentTaskVisualization();
      }

      if (reload) {
        this.list();
      }
    },

    handleCurrentTaskVisualization() {
      const now = new Date();

      if (isSameDay(now, this.date)) {
        for (let i = 0; i < this.plannedTasks.length; i += 1) {
          const taskDate = this.plannedTasks[i].recurring ? `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}` : this.plannedTasks[i].date;

          Vue.set(
            this.plannedTasks[i],
            'running',
            !this.plannedTasks[i].done
            && isAfter(now, new Date(`${taskDate} ${this.plannedTasks[i].start}`))
            && isBefore(now, new Date(`${taskDate} ${this.plannedTasks[i].end}`)),
          );
        }
      }
    },

    sortTodayTasks() {
      this.sortTasks(this.tasks.map((task) => task.id));
    },

    async moveToWorkspace(task) {
      try {
        const workspaceId = await this.$refs.choooseWorkspaceDialog.open();
        await this.moveTaskToWorkspace({ task, workspaceId });
        await this.list();
      } catch (e) {
        if (e !== undefined) {
          console.error(e);
        }
      }
    },
  },

  async mounted() {
    await this.list();
    this.runningTaskIntervalHandler = setInterval(this.handleCurrentTaskVisualization, 60000);

    window.addEventListener('focus', () => {
      this.$forceUpdate();
    });
  },

  beforeUnmount() {
    clearInterval(this.runningTaskIntervalHandler);
  },
};
</script>

<style lang="scss">
#routine-daily-view {
  .v-tabs .v-tab {
    &.current .v-subheader {
      background: whitesmoke;
      color: black;
    }

    .v-subheader {
      border-radius: 50%;
    }
  }
}
</style>
