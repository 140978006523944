<template>
  <v-dialog v-model="dialog" width="550">
    <v-form ref="form" @submit.prevent="select">
      <v-card>
        <v-card-title>Select workspace</v-card-title>

        <v-card-text>
          <v-select
            :items="$store.state.workspace.workspaces"
            item-text="name"
            item-value="id"
            v-model="workspace"
            :rules="[v => !!v || 'Choose workspace']"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text @click="close">Cancel</v-btn>

          <v-btn type="submit" color="primary">Select</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      resolve: null,
      reject: null,
      dialog: false,
      workspace: null,
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.workspace = null;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    close() {
      this.dialog = false;
      this.reject();
    },

    select() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.resolve(this.workspace);
      }
    },
  },
};
</script>
