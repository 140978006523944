<template>
  <v-dialog width="450" persistent v-model="dialog">
    <v-card>
      <v-card-title>Teendő törlése ({{ checklistItem.title }})</v-card-title>

      <v-card-text>
        Biztosan törli a kiválasztott teendőt?
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text @click="cancel">Mégse</v-btn>

        <v-btn color="error" @click="agree">Törlés</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      checklistItem: {
        title: '',
      },
    };
  },

  methods: {
    open(checklistItem) {
      this.dialog = true;
      this.checklistItem = checklistItem;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.dialog = false;
      this.resolve();
    },

    cancel() {
      this.dialog = false;
      this.reject();
    },
  },
};
</script>
