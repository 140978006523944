<template>
  <v-dialog width="450" persistent v-model="dialog">
    <v-card>
      <v-card-title data-testid="delete-task-title">
        Feladat törlése ({{ task.title }})
      </v-card-title>

      <v-card-text data-testid="delete-task-content">
        <span v-if="!task.recurring">
          Biztosan törli a kiválasztott feladatot?
        </span>

        <template v-else>
          <v-btn class="mb-2" color="primary" block @click="agree(1)" data-testid="delete-task-btn">
            Ezt az ismétlődést
          </v-btn>

          <v-btn class="mb-2" block @click="agree(2)" data-testid="delete-task-btn">
            Ezt és az összes ezt követő ismétlődést
          </v-btn>

          <v-btn class="mb-4" block @click="agree(0)" data-testid="delete-task-btn">
            Az összes ismétlődést
          </v-btn>

          <v-btn text block @click="cancel" data-testid="delete-task-cancel-btn">Mégse</v-btn>
        </template>
      </v-card-text>

      <v-card-actions v-if="!task.recurring">
        <v-spacer />

        <v-btn text @click="cancel" data-testid="delete-task-cancel-btn">Mégse</v-btn>

        <v-btn color="error" @click="agree(0)" data-testid="delete-task-btn">Törlés</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      task: {
        title: '',
        recurring: false,
      },
    };
  },

  methods: {
    open(task) {
      this.dialog = true;
      this.task = task;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree(type) {
      this.dialog = false;
      this.resolve(type);
    },

    cancel() {
      this.dialog = false;
      this.reject();
    },
  },
};
</script>
