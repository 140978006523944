<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="availableCategories"
      no-data-text="No category yet"
      disable-pagination
      hide-default-footer
      disable-sort
      data-testid="categories-table"
    >
      <template v-slot:body="{ items }">
        <draggable
          v-model="availableCategories"
          tag="tbody"
          handle=".table-dragger"
        >
        <tr
            v-for="(item, index) in items"
            :key="`row.${index}`"
          >
            <td v-if="showActions">
              <v-icon style="cursor: move;" class="table-dragger">mdi-drag</v-icon>
            </td>

            <td>{{ item.text }}</td>

            <td class="text-end">
              {{ item.sum }} {{ currency }}
              {{ item.max_amount ? ' / ' + item.all_max_amount + ' ' + currency : '' }}
              {{
                item.max_amount
                  ? ' (' +
                    Math.round(item.available / item.all_max_amount * 100) +
                    '%)'
                  : ''
              }}
            </td>

            <td class="text-end">
              <span :style="{color: item.available > 0 ? 'green' : 'red'}">
                {{ item.max_amount ? item.available + ' ' + currency : '' }}
              </span>
            </td>

            <td v-if="showActions">
              <v-btn
                v-if="item.value > 0"
                icon
                @click="$emit('edit', item)"
                data-testid="edit-category-button"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>

            <td v-if="showActions">
              <v-btn
                v-if="item.value > 0"
                icon
                @click="deleteCategory(item)"
                data-testid="delete-category-button"
              >
                <v-icon color="error" class="mr-2">mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>

    <v-row class="ma-0" v-if="shouldShowActions">
      <v-spacer />

      <v-checkbox label="Show actions" v-model="showActions" />
    </v-row>

    <confirm
      ref="deleteConfirm"
      title="Are you sure that you want to delete this category?"
      text="After that the finances connected to this category will be saved with no category,
      no finance will be deleted!"
      ok="Delete"
      buttonColor="error"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import Confirm from '../dialog/Confirm.vue';

export default {
  components: {
    draggable,
    Confirm,
  },

  props: {
    shouldShowActions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showActions: false,
    };
  },

  computed: {
    ...mapState('finance', ['finances', 'currency', 'categories']),

    tableHeaders() {
      let result = [
        { text: 'Category', value: 'text' },
        { text: 'Actual', value: 'amount', align: 'end' },
        { text: 'Available', value: 'diff', align: 'end' },
      ];

      if (this.showActions) {
        result = [
          ...[{ text: '', value: 'drag' }],
          ...result,
          ...[{ text: '', value: 'edit' }, { text: '', value: 'delete' }],
        ];
      }

      return result;
    },

    availableCategories: {
      get() {
        return this.categories.filter((category) => category.value > -1);
      },

      async set(value) {
        await this.$store.dispatch('finance/sortCategories', value);
        await this.$store.dispatch('finance/listCategories');
      },
    },
  },

  methods: {
    async deleteCategory(category) {
      try {
        await this.$refs.deleteConfirm.open();
        this.$emit('delete', category);
      } catch (e) {
        if (typeof e !== 'undefined') {
          console.error(e);
        }
      }
    },
  },
};
</script>
