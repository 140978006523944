<template>
  <div style="position: relative;">
    <finances-data-table @edit="onEditFinanceClick" />
    <edit-finance ref="editFinanceDialog" />

    <v-btn
      fab
      color="error"
      small
      fixed
      bottom
      style="right: 64px;"
      @click="onCreateFinanceClick('expense')"
    >
      <v-icon>mdi-cash-minus</v-icon>
    </v-btn>

    <v-btn
      fab
      color="green"
      small
      fixed
      bottom
      right
      @click="onCreateFinanceClick('income')"
    >
      <v-icon>mdi-cash-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import state from '../../store/modules/finance';
import EditFinance from '../finance/dialog/EditFinance.vue';
import FinancesDataTable from '../finance/FinancesDataTable.vue';

export default {
  components: { FinancesDataTable, EditFinance },

  computed: {
    ...mapState('finance', ['currentMonth']),
  },

  watch: {
    currentMonth() {
      this.listFinances();
      this.listCategories();
    },
  },

  methods: {
    ...mapActions('finance', ['listFinances', 'listCategories', 'saveFinance']),

    async onCreateFinanceClick(type) {
      try {
        const data = await this.$refs.editFinanceDialog.open({ type });
        await this.saveFinance(data);
        await this.listFinances();
      } catch (e) {
        if (typeof e !== 'undefined') {
          console.error(e);
        }
      }
    },

    async onEditFinanceClick(finance) {
      try {
        const data = await this.$refs.editFinanceDialog.open(
          JSON.parse(JSON.stringify(finance)),
        );

        await this.saveFinance({ ...finance, ...data });
        await this.listFinances();
      } catch (e) {
        if (typeof e !== 'undefined') {
          console.error(e);
        }
      }
    },
  },

  created() {
    if (!this.$store.hasModule('finance')) {
      this.$store.registerModule('finance', state);
    }
  },

  async mounted() {
    await this.listCategories();
    await this.listFinances();
  },
};
</script>
