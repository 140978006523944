<template>
  <div>
    <v-row class="mb-4 align-end">
      <v-col>
        <v-menu
          ref="menu"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <h1
              style="line-height: 1; white-space: nowrap;"
              v-bind="attrs"
              v-on="on"
              data-testid="finances-month"
            >
              {{ formattedMonth }}
            </h1>
          </template>

          <v-date-picker
            v-model="currentMonth"
            type="month"
            no-title
            scrollable
            :first-day-of-week="0"
            locale="en-US"
            color="primary"
          />
        </v-menu>
      </v-col>

      <v-spacer />

      <v-col cols="12" sm="3">
        <v-text-field
          v-model="financeSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          data-testid="finances-search"
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
          v-model="financeCategorySearch"
          :items="[...[{text: 'No filter', value: -2}], ...categories]"
          label="Filter categories"
          single-line
          hide-details
          data-testid="finances-filter"
          content-class="finances-filter"
        />
      </v-col>
    </v-row>

    <v-data-table
      :headers="tableHeaders"
      :items="filteredFinances"
      no-data-text="No finance data for selected month"
      sort-by="date"
      sort-desc
      disable-pagination
      hide-default-footer
      data-testid="finances-table"
    >
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.type="{ item }">
        <v-icon :color="item.type == 'expense' ? 'red' : 'green'">
          mdi-cash-{{ item.type == 'expense' ? 'minus' : 'plus' }}
        </v-icon>

        <v-icon v-if="item.should_review" color="warning" class="ml-1" data-testid="review-finance">
          mdi-alert
        </v-icon>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.amount="{ item }">
        {{ item.amount }} {{ currency }}
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.edit="{ item }">
        <v-btn icon @click="$emit('edit', item)" data-testid="edit-finance">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.delete="{ item }">
        <v-btn icon @click="removeFinance(item)" color="error">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:foot>
        <tfoot>
          <tr v-if="!loadAllFinances && count > 10 || allFinancesLoading">
            <td colspan="7">
              <v-btn text :loading="allFinancesLoading" @click="loadAll">Load more...</v-btn>
            </td>
          </tr>

          <tr>
            <td></td>

            <td data-testid="finances-count">{{ count }}</td>

            <td class="text-end" data-testid="finances-sum">
              <v-icon small>mdi-sigma</v-icon>

              {{ sum }} {{ currency }}
            </td>

            <td></td>

            <td data-testid="finances-unique-count">
              {{ uniqueCount }}
            </td>

            <td></td>

            <td></td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>

    <confirm
      ref="confirm"
      title="Are you sure?"
      cancel="Cancel"
      ok="Delete"
      buttonColor="error"
    />
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapActions, mapState } from 'vuex';
import { enUS } from 'date-fns/locale';
import Confirm from '../dialog/Confirm.vue';

export default {
  components: { Confirm },

  data() {
    return {
      financeSearch: '',
      financeCategorySearch: -2,
      tableHeaders: [
        { text: '', value: 'type', sortable: false },
        { text: 'Title', value: 'title' },
        { text: 'Amount', value: 'amount', align: 'end' },
        { text: 'Category', value: 'category_title' },
        { text: 'Date', value: 'date' },
        { text: '', value: 'edit', sortable: false },
        { text: '', value: 'delete', sortable: false },
      ],
    };
  },

  computed: {
    ...mapState('finance', ['finances', 'currency', 'categories', 'count', 'uniqueCount', 'sum', 'allFinancesLoading']),

    formattedMonth() {
      return format(new Date(this.currentMonth), 'y. MMMM', { locale: enUS, weekStartsOn: 1 });
    },

    currentMonth: {
      get() {
        return this.$store.state.finance.currentMonth;
      },

      set(value) {
        this.$store.state.finance.currentMonth = value;
      },
    },

    loadAllFinances: {
      get() {
        return this.$store.state.finance.loadAllFinances;
      },

      set(value) {
        this.$store.state.finance.loadAllFinances = value;
      },
    },

    filteredFinances() {
      let result = this.finances;

      if (this.financeSearch !== '') {
        result = result.filter(
          (finance) => finance.title.toLocaleLowerCase()
            .indexOf(this.financeSearch.toLocaleLowerCase()) !== -1,
        );
      }

      if (this.financeCategorySearch !== -2) {
        result = result.filter(
          (finance) => parseInt(finance.finance_category_id, 0)
            === parseInt(this.financeCategorySearch, 0),
        );
      }

      return result;
    },
  },

  methods: {
    ...mapActions('finance', ['deleteFinance', 'listFinances']),

    async removeFinance(item) {
      try {
        await this.$refs.confirm.open();
        await this.deleteFinance(item);
        this.listFinances();
      } catch (e) {
        if (e !== undefined) {
          console.error(e);
        }
      }
    },

    async loadAll() {
      this.loadAllFinances = true;
      await this.listFinances();
    },
  },
};
</script>
